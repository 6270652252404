<template>
	<div class="page-wrapper">
		<div class="page-header">
			<div class="page-header-title">
				<h1>Standard Operating Procedure For Cybercrime Investigation</h1>
			</div>

			<div class="action-buttons">
				<div class="button is-light" v-if="mode == 'EDIT'" @click="changeMode('VIEW')">
					<span class="icon mr-1">
						<icon icon="eye" />
					</span>
					View
				</div>
				<div class="button is-light" v-if="mode == 'VIEW'" @click="changeMode('EDIT')">
					<span class="icon mr-1">
						<icon icon="edit" />
					</span>
					Edit
				</div>
				<div class="button is-light" @click="deleteSOPs()">
					<span class="icon mr-1">
						<icon icon="trash" />
					</span>
					Delete
				</div>
				<div class="action-buttons button is-light" @click="saveSOPs()">Save</div>
			</div>
		</div>
		<div class="page-body">
			<div class="sop-title">
				<input type="text" class="input" placeholder="Enter SOP Title" v-model="data.title" />
			</div>

			<div class="content-wrapper">
				<textarea type="text" class="editor-container" v-model="data.dataContent" v-if="mode == 'EDIT'" />

				<div class="content view-container" v-html="$md.parse(data.dataContent)" v-if="mode == 'VIEW'"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.cjs.js"
import axios from "axios"

export default {
	data() {
		return {
			mode: "EDIT",
			data: {
				dataContent: "",
				title: "",
				createdBy: "Admin",
				createdAt: "2021-09-01",
				actions: [
					{
						type: "router-link",
						route: "SOPsView",
						key: "SOPsView",
						paramId: "SOPID",
						id: "",
						title: "view",
						icon: "eye"
					}
				],
				diagram:
					"graph TD\nA[Hard edge] -->|Link text| B(Round edge)\nB --> C{Decision}\nC -->|One| D[Result one]\nC -->|Two| E[Result two]"
			}
		}
	},
	methods: {
		showSOPs() {
			console.log(this.data, "DATA")
		},
		deleteSOPs() {
			this.data = ""
			// this.$refs.editor.clear()
			this.$router.push({ name: "SOPsList" })
		},
		saveSOPs() {
			axios
				.post("/user/digital-artifacts/create", this.data)
				.then(response => {
					if (response?.data?.success) {
						this.$router.push({ name: "SOPsList" })
					} else {
						console.log(response, "RESPONSE")
					}
				})
				.catch(error => {
					console.log(error, "ERROR")
				})
		},
		getValue(event) {
			console.log(event.target.value, "EVENT")
		},
		changeMode(mode) {
			this.mode = mode
			console.log(this.mode)
		}
	},
	computed: {
		...mapGetters(["sops"])
	},
	mounted() {
		// mermaid.initialize({
		// 	startOnLoad: true
		// })
		console.log(this.sops, "SOPs")
	},
	components: {
		// VueMermaidString
	}
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: 1rem;

	.mermaid svg {
		max-width: 100%;
		height: auto;
	}
	.page-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
		.page-header-title {
			h1 {
				font-size: 1.5rem;
				font-weight: 700;
				color: #333;
			}
		}
		.action-buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: $gap;
		}
	}
	.page-body {
		display: flex;
		flex-grow: 1;
		min-height: 500px;
		flex-direction: column;
		gap: $gap;
		.editor-container {
			// flex: 1;
			// margin-right: 1rem;
			// border: 1px solid #ccc;
			// border-radius: 0.25rem;
			// padding: 1rem;
			width: 100%;
			box-sizing: border-box;
			padding: 8px;
			font-size: 16px;
			border: 1px solid #ccc;
			border-radius: 4px;
			resize: none; /* Prevent manual resizing */
			overflow: hidden; /* Hide overflow */
		}
		.view-container {
			flex: 1;
			border: 1px solid #ccc;
			border-radius: 0.25rem;
			padding: 1rem;
			overflow: auto;
		}

		.sop-title {
			input {
				width: 30%;
				padding: 0.5rem;
			}
		}

		.content-wrapper {
			display: flex;
			flex: 1;
			gap: $gap;
		}
	}
}
</style>
